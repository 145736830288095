<template>
  <page-content>

    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      ref="edit_field"
      :fields="fields"
      :row-data="row_data"
      post-api="/member/AddMember"
      finish-router="member"
      permission="Mem_Create"
      @beforeSubmit="beforeSubmit"
    >
      <template #facility="scope">
        <SlotFacility :row-data="scope.rowData" :company-id="scope.rowData.companyID"
          :facility-id="scope.rowData.facilityID" :is-edit="scope.isEdit"
        />
      </template>

      <template #floor="scope">
        <SlotFloor :row-data="scope.rowData" :facility-id="scope.rowData.facilityID"/>
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData" :floor-id="scope.rowData.floorID"/>
      </template>

      <template #bed="scope">
        <SlotBed :row-data="scope.rowData" :room-id="scope.rowData.roomID"/>
      </template>

      <template #photo="scope">
        <SlotPhoto ref="photo" :row-data="scope.rowData"/>
      </template>

      <template #nfcTags="scope">
        <slot-inputs
          :root-item="scope.rootItem"
          :items="row_data['nfcTags']"
          @updateValues="(value) => row_data['nfcTags'] = value"
        />
      </template>

      <template #qrCodes="scope">
        <slot-qr-codes
          :root-item="scope.rootItem"
          :items="row_data['externalQrCodes']"
          @updateValues="(value) => row_data['externalQrCodes'] = value"
        />
      </template>

      <template #rfidTags="scope">
        <slot-inputs
          :is-edit="scope.isEdit"
          :root-item="scope.rootItem"
          :items="row_data['rfidTags']"
          @updateValues="(value) => row_data['rfidTags'] = value"
          :show-add-btn="false"
          :show-delete-btn="false"
        />
      </template>
    </edit-field>

  </page-content>
</template>

<script>
import fields, { reloadFieldData } from '@/views/member/fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import PageContent from '@/components/PageContent'
import SlotFacility from '@/views/member/SlotFacility'
import SlotFloor from '@/views/member/SlotFloor'
import SlotRoom from '@/views/member/SlotRoom'
import SlotBed from '@/views/member/SlotBed'
import SlotPhoto from '@/views/member/SlotPhoto'
import SlotQrCodes from '@/views/kiosk/kskMemberQr/FieldModalQrCodes.vue'
import SlotInputs from '@/components/FieldInputs'
import common from '@/common'
import _ from 'lodash'

export default {
  name: 'Add',
  components: {
    SlotPhoto,
    SlotBed,
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
    SlotRoom,
    SlotFloor,
    SlotFacility,
    SlotInputs,
    SlotQrCodes,
  },
  data() {
    return {
      title: common.getMenuName('member') + ' - ' + common.getI18n('common.add'),
      fields,
      // 如果是checkbox，里面写 field: [], 如ids: []
      row_data: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: null,
        roomID: null,
        bedID: null,
        refCode: null,
      },
    }
  },

  watch: {
    'row_data.facilityID': function (newValue, oldValue) {
      this.updateSuggestedRefCode()
    }
  },

  created() {
    reloadFieldData()

    // set default value from fields to forms
    this.fields.forEach(f => {
      if (f.default !== undefined && f.name) {
        this.$set(this.row_data, f.name, f.default)
      }
    })

    // TODO: 避免獲取公司院舍太慢，導致 UI 上只有 ID 無 name
    common.showLoading()
    setTimeout(() => {
      this.updateSuggestedRefCode(false)
      common.hideLoading()
    }, 500)
  },

  methods: {
    updateSuggestedRefCode(isShowToast = true) {
      const updateField = (refCode = null) => {
        const refCodePrev = this.row_data.refCode;
        const fields = _.cloneDeep(this.fields)
        const f = fields.find(f => f.name === 'refCode')
        f.placeholder = refCode
        f.datalist = refCode ? [{ value: refCode, label: this.$t('member.refCodeSuggested') }] : null
        this.fields = fields

        if (refCode !== refCodePrev) {
          this.row_data.refCode = refCode
          isShowToast && refCode && common.showToast({
            title: common.getI18n('member.refCodeChanged'),
            variant: 'info',
          })
        }
      };

      const facilityID = this.row_data.facilityID
      if ([null, undefined].includes(facilityID)) return updateField();

      this.$requestWehealth({
        method: 'get',
        url: '/member/GetSuggestMemberRefCode',
        params: { facilityID },
      })
        .then(({ data }) => {
          const refCode = data?.suggestCode ?? '';
          if (refCode) updateField(refCode)
          else updateField()
        })
        .catch(() => updateField());
    },

    beforeSubmit(callback) {
      const rowData = JSON.parse(JSON.stringify(this.$refs.edit_field.rowData));
      ['birthDate', 'joinedDate'].forEach((key) => {
        if (![null, undefined].includes(rowData[key]) && isNaN(Date.parse(rowData[key]))) {
          rowData[key] = null
        }
      })

      this.$nextTick(() => callback(true, rowData))
    }
  }
}
</script>

<style scoped>

</style>
